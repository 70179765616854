import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import {getBlob} from './server/api';

import VueCropper from 'vue-cropper' 
Vue.use(VueCropper)

import { ColorPicker, Button, Input } from 'element-ui';
Vue.use(Input)
Vue.use(Button)
Vue.use(ColorPicker)


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.prototype.$mm2px = (num)=>{
  return num/25.4*300;
}

const $px2mm = Vue.prototype.$px2mm = (num)=>{
  return num/300*25.4;
}

Vue.prototype.$img2blod = (src, callback)=>{
  getBlob(src).then(res=>{
      let reader = new FileReader();
      reader.readAsDataURL(res.data);
      reader.onload = (e)=> { //文件读取成功完成时触发
          let dataURL = e.target.result; //获得文件读取成功后的DataURL,也就是base64编码
          callback&&callback(dataURL);
      }
  })
},

Vue.prototype.$pt2px = (num)=>{
  return num/(72/300);
}

Vue.prototype.$px2pt = (num)=>{
  return parseInt(num*(72/300));
}

//var rad = deg * Math.PI/180;
Vue.prototype.$deg2radian = (deg)=>{
  return deg * Math.PI/180;
}

Vue.prototype.$calcHypotenuse = (a, b)=>{
  return (Math.sqrt((a * a) + (b * b)));
}

//修正老编辑器字体宽度不统一问题
Vue.prototype.$fontBoxWidthFix = (value, fontSize)=>{
  fontSize += fontSize*0.15;
  const valueRows = value.split('\n');
  const valueRowsLen = valueRows.length;
  const valueRowLens = valueRows.map(v=>v.length);
  const maxVRL = Math.max(...valueRowLens);
  return {
    width: $px2mm(maxVRL*fontSize),
    height: $px2mm((valueRowsLen+1)*fontSize)
  };
}

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
