<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
const Resize = () => {
    let screenWidth = document.documentElement.clientWidth
    if (screenWidth > 430) {
        screenWidth = 430
    }
    document.documentElement.style.fontSize = `${screenWidth / 7.5}px`
}
// 监听页面尺寸变动 调整rem
Resize()
import {
  getFontStyle
} from './server/api'
import { mapMutations } from 'vuex';
export default {
  name: 'App',
  data(){
    return {
      list: []
    }
  },
  mounted(){
    this.initFonts();
  },
  methods:{
    ...mapMutations([
      'setFontList',
      'setSkuData'
    ]),
    initFonts(){
      getFontStyle().then(res=>{
        res = res.data;
        if (res.flag) {
          this.list = res.data
          localStorage.setItem('fontlist',JSON.stringify(res.data))
          this.setFontList(res.data);
          let style = document.createElement('style')
          style.attributes['type'] = 'text/css'
          style.id = 'fontStyle'
          let str = ''
          if (this.list && this.list.length) {
            for (let i = 0; i < res.data.length; i++) {
              str += `@font-face {font-family: 'f${res.data[i].id}';src: url('https://xiaoxiangzhizao.ele007.com${res.data[i].fontPath}') format("truetype")}`
            }
          }
          style.innerText = str
          document.getElementsByTagName('head')[0].appendChild(style)
        }
      })
    }
  }
}
</script>
<style>
html,body{
  height: 100%;
  overflow: hidden;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,button,textarea,p,blockquote,th,td { margin:0; padding:0; }
body { touch-action: none; background:#EFEFEF; color:#555; font-size:14px; font-family: Verdana, Arial, Helvetica, sans-serif; }
td,th,caption { font-size:14px; }
h1, h2, h3, h4, h5, h6 { font-weight:normal; font-size:100%; }
address, caption, cite, code, dfn, em, strong, th, var { font-style:normal; font-weight:normal;}
a { color:#555; text-decoration:none; }
a:hover { text-decoration:underline; }
img { border:none; }
ol,ul,li { list-style:none; }
input, textarea, select, button { font:14px Verdana,Helvetica,Arial,sans-serif; }
table { border-collapse:collapse; }
html {overflow-y: scroll;}

.clearfix:after {content: "."; display: block; height:0; clear:both; visibility: hidden;}
.clearfix { *zoom:1; }

.van-popup--bottom.van-popup--round{
  border-radius: 10px 10px 0px 0px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
