import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';

//创建请求主体
const $ajax = axios.create({
  //请求地址的前缀，比如输入的url是  elink/get; 前缀设置为api，则请求的全路径为： api/elink/get
  baseURL: '' ,
  //设置请求的超时阈值，超过该时间则中断请求，显示超时
  timeout: 30000,
});

//处理错误
const err = (error) => {
    //error.response
    //处理返回的错误信息
    const response = error.response.data;

    if(response.code==403){
        Toast.error('403')
    }

    if(response.code==401){
        Toast.error('401');
    }

    if(response.code==500){
        Toast.error(response.message);
    }
    return Promise.reject(error);
};

//请求拦截
$ajax.interceptors.request.use(config => {
    //此处设置请求前置请求头信息，
    //例如设置 token， cookie 等信息，帮助验证登录状态
    //设置请求的默认token

    const search = qs.parse(location.search.split('?')[1])
    const token = search.token;
    if(token){
      config.headers.Authorization = 'Bearer '+token;
    }
    return config
}, err);

// 响应拦截器
$ajax.interceptors.response.use(response => {
  return response
}, err)

//params  type: Object
export default {
  get: function (url, params) {
    return $ajax({
      method: 'get',
      url,
      params: params,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest'
      }
    })
  },
  postUrl: function (url, params) {
    return $ajax({
      method: 'post',
      url,
      params: params,
      headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },
  postJson: function (url, params) {
    return $ajax({
      method: 'post',
      url,
      data: params,
      headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
      }
    })
  },
  postForm: function (url, params, source) {
    return $ajax({
      method: 'post',
      url,
      data: params,
      cancelToken: source?source.token:"",
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest'
      }
    })
  },
  getBlob: function (url, params, onDownloadProgress) {
    const noop = function(){};
    if(url.indexOf('?')===-1){
      url = url+'?'+qs.stringify(params);
    }
    return $ajax({
        method: 'get',
        url,
        responseType: 'blob',
        onDownloadProgress: onDownloadProgress||noop,
        headers: {
            'blob': 'true'
        }
    });
  },
  postBlob: function (url, params, onDownloadProgress) {
    const noop = function(){};
    return $ajax({
        method: 'post',
        url: url,
        data: params,
        responseType: 'blob',
        onDownloadProgress: onDownloadProgress||noop,
        headers: {
            'Content-Type': 'application/json',
            'blob': 'true'
        }
    });
  }
}
