import todos from './modules/todos';
import Vue from 'vue';
import Vuex from 'vuex';
import { uuid } from 'vue-uuid';
import _ from 'lodash'

const $deg2radian = (deg)=>{
    return deg * Math.PI/180;
}

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    todos
  },
  state: {
    boardRotate: 0,
    historyColor:['','','','',''],
    fontList: [],
    /** 当前所有元素组成的 stock keeping unit */
    skuData:{},
    copyItem:{},
    current: {},
    loading: {},
    historyAction:[]
  },
  mutations: {
    updateHistoryAction(state, item){
        let skuData = _.cloneDeep(item);
        skuData.active = true;
        let oldIndex = 0;
        // active 为 true 的 action 的 index 设为 oldIndex
        state.historyAction = state.historyAction.map((v,i)=>{
            if(v.active) oldIndex = i;
            v.active = false;
            return v;
        });
        state.historyAction = state.historyAction.filter((v,i)=>i<=oldIndex);
        // 新的 item 的 active 为 true，放到 historyAction 的最后面
        state.historyAction.push(_.cloneDeep(skuData))
        if(state.historyAction.length>20){
            state.historyAction.shift();
        }
    },
    initHistoryAction(state, historyAction){
        state.historyAction = historyAction;
    },
    /** 指定某 index 的 action 的 active 为 true */
    setActiveHistory(state, index){
        state.historyAction = state.historyAction.map((v,i)=>{
            v.active = false;
            if(i===index) v.active = true;
            return v;
        })
    },
    /** 
     * 计算原 item 旋转后占据空间的水平宽和垂直高, 存入 state.skuData.content 里
     * 更新了 curItem.style 里的 angle、
     * outerWidth、outerHeight、
     * sateW、sateH、
     * fullHeight、fullWidth、
     * originWidth、originHeight 
     */
    calcOuterWH(state, currItem){
    
        let width = currItem.style.width;
        let height = currItem.style.height;
        // angleA 和 angleB 的单位是弧度 ; currItem.style.angle 的单位是度
        let angleA, angleB; 

        currItem.style.angle = Number(currItem.style.angle);

        if(currItem.style.angle>0&&currItem.style.angle<90){
            //第一象限
            angleA = $deg2radian(currItem.style.angle);
            angleB = $deg2radian(90-currItem.style.angle);

            // outerWidth 和 outerHeight 是原 item 旋转后, 占据的空间的水平垂直宽和高
            currItem.style.outerWidth = Math.cos(angleA)*width + Math.cos(angleB)*height;
            currItem.style.outerHeight = Math.sin(angleA)*width + Math.sin(angleB)*height;
            
        } else if(currItem.style.angle===90||currItem.style.angle===270){
            currItem.style.outerWidth = height;
            currItem.style.outerHeight = width;
        } else if(currItem.style.angle>90&&currItem.style.angle<180){
            //第二象限
            angleA = $deg2radian(180-currItem.style.angle);
            angleB = $deg2radian(90-(180-currItem.style.angle));

            currItem.style.outerWidth = Math.cos(angleA)*width + Math.cos(angleB)*height;
            currItem.style.outerHeight = Math.sin(angleA)*width + Math.sin(angleB)*height;
            
        } else if(currItem.style.angle===180||currItem.style.angle===0){
            currItem.style.outerWidth = width;
            currItem.style.outerHeight = height;
        } else if(currItem.style.angle>180&&currItem.style.angle<270){
            //第三象限
            angleA = $deg2radian(currItem.style.angle-180);
            angleB = $deg2radian(90-(currItem.style.angle-180));

            currItem.style.outerWidth = Math.cos(angleA)*width + Math.cos(angleB)*height;
            currItem.style.outerHeight = Math.sin(angleA)*width + Math.sin(angleB)*height;
            
        }  else if(currItem.style.angle>270&&currItem.style.angle<360){
            //第四象限
            angleA = $deg2radian(360-currItem.style.angle);
            angleB = $deg2radian(90-(360-currItem.style.angle));

            currItem.style.outerWidth = Math.cos(angleA)*width + Math.cos(angleB)*height;
            currItem.style.outerHeight = Math.sin(angleA)*width + Math.sin(angleB)*height;
        }

        // TODO sateW 和 sateH 含义是 ？        
        currItem.style.sateW = (currItem.style.outerWidth-currItem.style.width)/2;
       
        currItem.style.sateH = (currItem.style.outerHeight-currItem.style.height)/2;

        currItem.style = {
            ...currItem.style,
            fullHeight: currItem.style.height,
            fullWidth: currItem.style.width,
            originHeight: currItem.style.height,
            originWidth: currItem.style.width,
        }

        // 更新 skuData.content 里的当前 item
        state.skuData = {
            ...state.skuData,
            content:state.skuData.content.map(v=>{
                if(v.uid===currItem.uid){
                    return currItem;
                }
                return v;
            })
        };
    },
    setSkuData(state, item){
        state.skuData = {
            ...state.skuData,
            ...item
        }
    },
    setFontList(state, list){
        state.fontList = list;
    },
    setHistoryColor(state, color){
        state.historyColor.unshift(color);
        state.historyColor.length = 5;
    },
    //删除
    delContent(state, uid){
        state.skuData.content = state.skuData.content.filter(v=>v.uid!=uid);
    },
    //复制
    copyContent(state, uid){
        let copyItem = state.skuData.content.find(v=>v.uid==uid);
        if(copyItem) {
            copyItem = JSON.parse(JSON.stringify(copyItem));
            copyItem.uid = uuid.v1();
            copyItem.id = '';
            copyItem.style.zIndex = state.skuData.content.length+501;
            copyItem.style.left -= -15;
            copyItem.style.top -= -15;
            copyItem.topLock = false;

        }
        state.copyItem = copyItem;
        state.skuData.content = [...state.skuData.content, copyItem];
    },
    setBoardRotate(state){
        let rotate = state.boardRotate+90;
        if(rotate>=360) rotate = 0;
        state.boardRotate = rotate;
    },
    setContent(state, item){
        this.commit('calcOuterWH', item);
    },
    addContent(state, item){
        let indexs = state.skuData.content.map(v=>v.zIndex);
        let maxIndex = Math.max(...indexs);
        state.skuData = {
            ...state.skuData,
            content:[...state.skuData.content, {
                "uid": uuid.v1(),
                "layer": "500",
                "topLock": false,
                "type": "IMAGE",
                "warning": null,
                "craftId": null,
                "craftArea": null,
                "style": {},
                "value": "",
                "srcId": "",
                "bgX": 0,
                "bgY": 0,
                "bgWidth": 0,
                "bgHeight": 0,
                "previewUrl": "",
                ...item,
                zIndex: maxIndex+1
            }]
        };
    },
    /** TODO 整个项目中没用到过 为什么 */
    resetCurrent(state) {
      state.current = JSON.parse(localStorage.getItem('Current')) || {};
    },
    saveLoading(state, loading) {
      state.loading = {
        ...state.loading,
        ...loading
      }
    },
    save(state, payload) {
      state = {
        ...state,
        ...payload
      };
    }
  }
});
export default store;
