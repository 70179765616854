import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes:[{
        path:'/',
        name: 'main',
        component: ()=>import('@/view/CanvasPage')
    },{
        path:'/perview3d',
        name: 'ThreeDPreview',
        component: ()=>import('@/components/ThreeDPreview')
    }]
})