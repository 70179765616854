import http from '../utils/axios'
import qs from 'qs'

/** 获取字体素材 */
export const getFontStyle = (langeType='')=>{
    return http.postJson('/api/wx/compiler/materialFont', {
        langeType
    });
}

/** 查询素材组 */
export const materialGroup = (categoryType='GRAPHICS')=>{
    return http.get('/api/wx/compiler/materialGroup', {
        categoryType
    });
}

/** 查询素材组详情 */
export const materialGroupDetail = (id)=>{
    return http.get('/api/wx/compiler/materialGroupDetail', {id});
}

/** 编译器初始化商品原型数据/我的设计详情 */
export const initData = (params)=>{
    return http.get('/api/wx/compiler/initializeDesign', params);
}

export const initDatajd = (params)=>{
    return http.get('/api/jd/order/initDesign', params);
}

export const getBlob = (url)=>{
    return http.getBlob(url);
}

export const get = (url)=>{
    return http.get(url);
}

// /api/model-3d/textures?fid=07f9958d083241f1b694a4d63e2205c1
export const textures = (params)=>{
    return http.get('api/model-3d/textures', params);
}

// SKETCH、2D资产、KIND、尺寸图、设计图资产上传到图片管理表
export const designImage = ({query, params}) => {
    return http.postForm('api/wx/compiler/designImage?'+qs.stringify(query), params);
}

export const previewimg = (data) => {
    return http.postForm('api/wx/compiler/designImage', data);
}

// 裁剪图片
export const cutImage = (data) => {
    return http.postJson('api/wx/compiler/designImageTailor', data);
}

// 删除个人素材
export const updateUserDesign = (params) => {
    return http.get('api/wx/compiler/updateUserDesign', params)
}

// 获取个人素材
export const materialUserDesign = (params) => {
    return http.get('api/wx/compiler/materialUserDesign', params);
}

// 保存所有素材
export const savetemplateDesign = (data) => {
    return http.postJson('api/wx/compiler/saveTemplateDesign', data)
}

export const designSave = (data) => {
    return http.postJson('api/jd/order/designSave', data)
}

// 上传个人素材
export const uploadPersonMaterial = (data) => {
    return http.postJson('api/wx/compiler/uploadUserMaterial', data)
}

// 工艺列表
export const craftList = (data) => {
    return http.get('api/wx/compiler/craftList', data)
}

// 后台保存 /api/template-designs/edit
export const designsedit = (data) => {
    return http.postJson('api/template-designs/edit', data)
}

// 后台获取素材接口 /api/template-profiles/materialGroupPage
export const materialGroupPage = (data) => {
    return http.get('api/template-profiles/materialGroupPage', data)
}

export const getEnabledOrNot = (data) => {
    return http.get('api/item/getEnabledOrNot', data)
}
